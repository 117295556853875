import React from 'react';
import Layout from "../../components/calculator/layout";
import SEO from "../../components/seo";

import Sidebar from "../../components/calculator/sidebar";
import Step2 from "../../components/calculator/step-2";

const CalculatorStep2 = (props) => (
    <Layout>
        <SEO title="Calculator Step 2"/>
        <div id="wrapper" class="d-flex justify-content-between">
                  <Sidebar {...props}/> 
            <Step2/>
        </div>
     
    </Layout>
)

export default CalculatorStep2;

