//import { Link } from "gatsby"
import React from "react"
import { StaticQuery, graphql } from "gatsby"

const Step2 = () => (
  <StaticQuery
    query={graphql`
      query {
        allWpPage(filter: {slug: {eq: "calculator"}}) {
          edges {
            node {
              TeamlanceCalculator {
                calculatorSteps {
                  emailPlaceholder
                  fieldGroupName
                  namePlaceholder
                  phonePlaceholder
                  privacyText
                  steps {
                    buttonText
                    description
                    fieldGroupName
                    heading
                    iconSvg
                    icon {
                      sourceUrl
                    }
                    options {
                      optionTitle
                    }
                    percentComplete
                    title
                  }
                }
                designSettings {
                  buttonBg
                  leftMenuBg
                  leftTextColor
                  logo {
                    sourceUrl
                  }
                  logoSvg
                  rightTextColor
                }
              }
              slug
            }
          }
        }
      }
    `}
    render={data => {
      return(
        data && data.allWpPage &&
        data.allWpPage.edges &&
        data.allWpPage.edges[0] &&
        data.allWpPage.edges[0].node &&
        data.allWpPage.edges[0].node.TeamlanceCalculator &&
        data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps &&
        data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps&&
        data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps.length>0&&
        <div className="mainContentWrapper">
          <div onclick="location.href = 'calculator-step-1.html'; return false;" className="back d-flex align-items-center">
            <svg width={7} height={10} viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.5 8.75L1.75 5L5.5 1.25" stroke="#C4C4C4" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <p className="mb-0 ml-3">Back</p>
          </div>
          <div className="row dashboardCardRow">
            <div className="col-md-10 col-lg-8 d-flex align-items-center ">
              <div className="dashboard-content">
                <figure dangerouslySetInnerHTML={{__html:data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps[1].iconSvg}} className="my-5"></figure>
                <h1>{data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps[1].heading}</h1>
                <p className="mb-5">
                  {
                    data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps[1].description
                  }
                </p>
                <form>
                  <div className="form-check-inline radio-form">
                    <label className="customradio mb-0">
                      <span className="radiotextsty">{data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps[1].options[0].optionTitle}</span>
                      <input type="radio" defaultChecked="checked" name="radio" />
                      <span className="checkmark" />
                    </label>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                  </div>
                  <div className="form-check-inline radio-form">
                    <label className="customradio">
                      <span className="radiotextsty">{data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps[1].options[1].optionTitle}</span>
                      <input type="radio" name="radio" />
                      <span className="checkmark" />
                    </label>
                  </div>
                </form>
                <div className="text-left">
                  <link to="/calculator/calculator-step-3" />
                 
                  <button onclick="location.href = 'calculator-step-3'; return false;" data-abc="true" className="btn btn-default waves-effect waves-light next-step">
                    {data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps[1].buttonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }}
  />
  
)

export default Step2
